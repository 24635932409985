<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" @close="close">
    <div class="news-content" v-html="content" />
    <div v-if="this.isLoading" class="login-botton">
      <el-button @click="close">已阅读完成</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getAgreement } from "@/api/agreementController";
export default {
  name: "ProtocolDialog",
  data() {
    return {
      content: "正在加载协议...",
      dialogFormVisible: false,
      type: "",
      isLoading: false,
      title: "",
    };
  },
  created() {},
  methods: {
    getAgreement() {
      getAgreement(this.type).then((res) => {
        if (res.code == 20000) {
          this.isLoading = true;
          this.content = res.data.content;
        } else {
          this.$baseMessage(
            "协议加载失败,code:" + res.code + ",msg:" + res.msg,
            "error",
            "vab-hey-message-error"
          );
        }
      });
    },
    showEdit(type) {
      this.content = "正在加载协议...";
      this.dialogFormVisible = true;
      this.type = type;
      this.isLoading = false;
      this.getAgreement();
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.login-botton {
  margin-top: 30px;
  button {
    height: 50px;
    background: #173d7c;
    border-radius: 10px;
    cursor: pointer;
    border: 0;
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    &:hover {
      background: #244f96;
    }
  }
}
</style>
