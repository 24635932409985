<template>
  <footer class="LoginFooter">
    <div class="FooterCenter">
      <ul>
        <li><a href="http://www.cufehl.com/" target="_balnk">首页华路</a></li>
        <!--<li>•</li>
        <li><router-link to="/PurchasePage">选课中心</router-link></li>-->
        <li>•</li>
        <li><router-link to="/purchaseitemInfo">联系我们</router-link></li>
        <!-- <li>•</li>
        <li>华路校友会</li>
        <li>•</li>
        <li>华路商学院简介</li>
        <li>•</li>
        <li>我要约课</li> -->
      </ul>
      <div class="FooterRight">
        <p>COPYRIGHT © 2009-2020 华路数字文化 版权所有</p>
        <p>www.cufehl.com 粤ICP备14094585号-1</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LoginFooter",
  components: {},
};
</script>

<style lang="scss" scoped>
.LoginFooter {
  border-top: 1px solid #bfbfbf;
  .FooterCenter {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul {
      float: left;
      li {
        float: left;
        margin-right: 20px;
        a {
          color: #545a5b;
        }
      }
    }
    .FooterRight {
      float: right;
      text-align: right;
      p {
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
</style>
